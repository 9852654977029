import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiErrorInterface } from '../interfaces/api-error.interface';
import { UnauthorizePromptService } from '../service/auth/unauthorize-prompt/unauthorize-prompt.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(
    public readonly toastrService: ToastrService,
    private readonly unauthorizePromptService: UnauthorizePromptService,
  ) { }

  handleError(error: HttpErrorResponse): void {
    // Log the error
    console.error("HTTP Error:", error);

    // Handle specific HTTP status codes
    if (error.status === 401) {
      this.handleUnauthorized();
    } else if (error.status === 403) {
      this.handleForbidden();
    } else if (error.status === 404) {
      this.handleNotFound(error.error);
    } else if (error.status >= 500) {
      this.handleServerError(error.error);
    } else {
      this.handleGenericError(error.error);
    }
  }

  private handleUnauthorized(): void {
    this.unauthorizePromptService.showPopup();
  }

  private handleForbidden(): void {
    this.unauthorizePromptService.showPopup();
  }

  private handleNotFound(error: ApiErrorInterface): void {
    this.toastrService.error(error.message, 'Resource not found.', { timeOut: 5000 });
  }

  private handleServerError(error: ApiErrorInterface): void {
    this.toastrService.error(error.message, 'Server error occurred.', { timeOut: 5000 });
  }

  private handleGenericError(error: ApiErrorInterface): void {
    this.toastrService.error(error.message, 'An unexpected error occurred.', { timeOut: 5000 });
  }

}
