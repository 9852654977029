import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, } from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { UnauthorizePromptService } from "../unauthorize-prompt/unauthorize-prompt.service";
import { catchError } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { isNewErrorSchema } from "src/app/shared/utils/is-new-error-schema";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private session = [];
  constructor(
    private router: Router,
    private notAuth: UnauthorizePromptService,
    private transloco: TranslocoService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.session = Object.keys(sessionStorage);
    let user = null;
    let tokenJson = null;

    if (this.session.length) {
      user = this.session.find(function (key) {
        return key.includes("oidc.user:");
      });
    }


    if (user) tokenJson = JSON.parse(sessionStorage.getItem(user));

    const token: string = tokenJson ? `${tokenJson.token_type} ${tokenJson.access_token}` : "";
    request = request.clone({
      setHeaders: {
        Authorization: token,
        "Accept-Language": this.getActiveLanguage()
      },
    });

    return next.handle(request).pipe(
      catchError((error) => {
        const isSigninCallback = this.router.routerState.snapshot.url.includes('signin-callback');

        if (isNewErrorSchema(error.error) && !isSigninCallback) {
          this.errorHandlerService.handleError(error);
          return EMPTY;
        } else if ((error.status === 401 || error.status === 403) && !isSigninCallback) {
          this.notAuth.showPopup()
        }

        return throwError(error);
      })
    );
  }

  private getActiveLanguage(): string{
    let activeLang = this.transloco.getActiveLang().toLowerCase();
    let language = activeLang;

    switch (activeLang) {
      case 'et': language = 'et-EE'; break;
      case 'ru': language = 'ru-RU'; break;
      case 'en':
      case 'en-us':
      case 'en-gb': language = 'en-GB'; break;
    }
    return language;
  }
}
